<template lang="pug">
.maintain.d-flex.align-center.justify-center
  .description.d-flex.flex-column.align-center.justify-center
    font-awesome-icon(:icon="['fas', 'tools']")
    h3.mt-4 Sorry, 網頁維修中...
</template>
<script>
export default {
  name: "Maintain",
};
</script>

<style lang="scss" scoped>
.maintain {
  width: 100%;
  height: 100%;
}

.description {
  margin: auto;
  text-align: center;
  height: 300px;
  color: $f_primary;
}

svg.fa-tools {
  font-size: 40px;
}

@media (max-width: 599px) {
  h3 {
    font-size: 16px;
  }
  svg.fa-tools {
    font-size: 30px;
  }
}
</style>